<template>
 <div :class="windowClass">
   <v-container>
        <v-row no-gutters>
      <v-col
        md="10"
        offset-md="1"
        class="loginbg"
         style="min-height: 600px !important; position: relative !important;"
        
      >         <v-toolbar dense color="#4098BA" dark class="mb-3">
                    <span class="font-weight-black">2D</span> <v-spacer/>
                      <v-menu bottom left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="primary"
                              dark
                              small
                              v-on="on"
                            >
                             {{ $moment(draw.date +" "+draw.time).format("MMM DD HH:mm a") }}
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in Draws"
                              :key="index + 'item-draw'"
                              @click="draw=item"
                              small
                            >
                              <v-list-item-title small> {{ $moment(item.date +" "+item.time).format("MMM DD HH:mm a") }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                </v-toolbar>
                <template v-for="(item, index) in Lines">
                      <v-row no-gutters :key="index +'-l'">
                          <v-btn class="ma-1" small color="secondary" fab v-if="item.num1 != null">{{item.num1}}</v-btn>
                          <v-btn class="ma-1" small color="secondary" fab v-if="item.num2 != null">{{item.num2}}</v-btn>
                          <v-btn class="ma-1" small color="secondary" fab v-if="item.num3 != null">{{item.num3}}</v-btn>
                          <v-spacer/>
                          <v-btn class="ma-1" small color="warning" fab v-if="item.total_amount != 0">{{item.total_amount}}</v-btn>
                      </v-row> 
                      <v-divider class="ma-1" color="#ececec" :key="index +'-p'"/>
                </template>
                <v-row no-gutters>
                    <v-btn class="ma-1" small color="secondary" fab v-if="line.num1 != null">{{line.num1}}</v-btn>
                    <v-btn class="ma-1" small color="secondary" fab v-if="line.num2 != null">{{line.num2}}</v-btn>
                    <v-btn class="ma-1" small color="secondary" fab v-if="line.num3 != null">{{line.num3}}</v-btn>
                    <v-spacer/>
                    <v-btn class="ma-1" small color="warning" fab v-if="line.line_amount != null">{{line.line_amount}}</v-btn>
                </v-row> 
                  <v-divider class="ma-1" color="#ececec"  v-if="line.num1 != null"/>
                  <v-row no-gutters class="title font-weight-black white--text" dark>
                       <v-spacer/>
                       <div class="pa-3" v-if="TOTAL_AMOUNT != 0">Amount: {{$money(TOTAL_AMOUNT)}}</div>
                  </v-row>   


                <v-footer
                    style="position: absolute; width: 100%; bottom: 5px; background: none;"  
                   
                    padless
                    inset
                >
                <v-row
                justify="center"
                no-gutters
                >
                
                <v-col
                    md="8"
                >   
                    <template v-for="i in 10">
                             <v-btn :key="i" class="ma-1" @click="addNumberDigit(i-1)" fab>{{i-1}}</v-btn>
                    </template>
                    <v-btn class="ma-1" color="warning" fab @click="confirm_type='reset_ticket', confirm_message='Reset Ticket?',confirm_cancel='Close', confirm_ok='reset' , confirm=true">Reset</v-btn>
                    <v-btn class="ma-1" color="error" fab @click="confirm_type='clear_line', confirm_message='Clear line?',confirm_cancel='Close', confirm_ok='Clear' , confirm=true">Clear</v-btn>
                     <v-btn class="ma-1" color="secondary" fab @click="setType('STD')">OK</v-btn>
                      <!-- <v-btn class="ma-1" fab  color="info" @click="setType('RBL')">RBL</v-btn> -->
                      <v-btn class="ma-1" fab  color="success" @click="confirm_type='buy_ticket', confirm_message='Buy this ticket?',confirm_cancel='Close', confirm_ok='Buy' , confirm=true" v-if="Lines.length > 0">Buy</v-btn>
                </v-col>
                </v-row>
            </v-footer>
      </v-col>
    </v-row>
   </v-container>
   <va-amount-form :show="amountform" :type="line.line_type" :draw="draw" @DialogEvent="afEvent"/>
  <va-confirm :show="confirm" :message="confirm_message" :okbtn="confirm_ok" :cancelbtn="confirm_cancel" @ConfirmEvent="cdEvent"/>
  <game-ticket :show="show_ticket" :ticket="Ticket" @DialogEvent="tfEvent"/>
  </div>
</template>
<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
    mixins: [API_MIXINS],
    data: () => ({
      game_name: '2D',
      show_ticket: false,
      confirm: false,
      confirm_message:'',
      confirm_ok:'',
      confirm_cancel: '',
      confirm_type:'',
      amountform: false,
      Draws:[],
      draw:{},
      Ticket:{},
      Sold:[],
      Games:[],
      Lines:[],
      line:{
        num1:null, num2:null, total_amount: null, draw_id: null, line_type: null, line_amount:null
      }
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getDraws()
        } 
        this.Games =[{game: "3D", bg: this.$store.state.game_logo.three_num},
          {game: "2D",  bg: this.$store.state.game_logo.two_num},
          {game: "4D", bg: this.$store.state.game_logo.four_num}
      ]
      this.clearLine()
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      TOTAL_AMOUNT(){
        var total = this.Lines.reduce((a,o)=> a+=this.$IsNum(o.total_amount), 0)
        return total
      },
      TOTAL_LINE_AMOUNT() {
        if( this.line.line_type == 'RBL') {
          return this.$IsNum(this.line.line_amount) * 6
        }
        return this.line.line_amount
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      cdEvent(data){
        console.log(data)
        this.confirm = false
        if(data == 'ok') {
          if(this.confirm_type =='clear_line') {
            this.clearLine()
          }
          if(this.confirm_type =='buy_ticket') {
            this.Proceed()
          }
          if(this.confirm_type =='reset_ticket') {
          this.clearTicket()
          }
        }
      },
      tfEvent() {
        this.show_ticket = false
        this.clearTicket()
      },
      clearTicket(){
        this.clearLine()
        this.Ticket = {}
        this.Lines = []
        this.getDraws()
      },
      clearLine(){
         this.line ={
          num1:null, num2:null, total_amount: null, line_type: null, line_amount:null
        }
      },
      afEvent(data) {
         this.amountform = false
          if('add_line'==data.action) {
              this.line.line_amount = data.amount.trim()
              this.addLine()
          }
      },
      Proceed(){
        this.Ticket.Lines = this.Lines
        this.Ticket.draw_id=this.draw.id
        this.Ticket.game = this.game_name
        this.Ticket.amount = this.TOTAL_AMOUNT.toString()
        this.Ticket.draw_date= this.$moment(this.draw.date +" "+this.draw.time).format("MMM DD HH:mm a")
        this.newTicket()
      },
      newTicket(){
        console.log(this.Ticket)
          this.$http.post("ticket/new", this.Ticket).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
            if(response.data.status){
              this.Ticket = response.data.ticket
              this.show_ticket = true
              }
       }).catch(e => {
            console.log(e)
        })
      },
      addLine(){
        if(this.validateLineCombi()) {
          this.line.total_amount =  this.TOTAL_LINE_AMOUNT.toString()
          this.Lines.push(this.line)
        }
        this.clearLine()
      },
      validateLineCombi(){
          for( var i = 0; i<this.Sold.length;i++) {
             if(this.line.num1 == this.Sold[i].num1 && this.line.num2 == this.Sold[i].num2 && this.line.num3 == this.Sold[i].num3) {
               this.VA_ALERT('error',"Combination is sold out!")
               return false
             }
          }
          return true
      },
      setType(type){
        this.line.line_type = type
        this.amountform = true
      },
      addNumberDigit(digit){
            if(this.line.num1 == null) {
              this.line.num1 = digit
              return
            }
            if(this.line.num2 == null) {
              this.line.num2 = digit
              return
            }
      },
       getDraws(){
           this.$http.post("draw/get", {game:this.game_name, status:"Open"}).then(response => {
            response.data.draws != null?this.Draws= response.data.draws:this.Draws = []
            response.data.draws != null?this.draw= response.data.draws[0]:this.draw = {}
            this.getSoldOut()
        }).catch(e => {
            console.log(e)
        })
      },
      getSoldOut() {
         this.$http.post("soldout/get", {game:this.game_name, draw_id:this.draw.id}).then(response => {
           response.data.sold != null?this.Sold= response.data.sold:this.Sold = []
           console.log(this.Sold)
        }).catch(e => {
            console.log(e)
        })
      }
    },
  }
</script>